import { ProductShelfCategoryKey } from '@spa-core/store/products/constants'

export const NAME: string = 'ProductTable'

export type ProductTableTheme = {
    hideShelfTitleOnMobile?: boolean
    categoriesOrder?: ProductShelfCategoryKey[]
    checkCategoriesForProducts?: ProductShelfCategoryKey[]
    showPrinterModelRecommendation?: boolean
    showVaccumModelRecommendation?: boolean
    backgroundGutter?: boolean
    headerModelComponentSameRow?: boolean
}
