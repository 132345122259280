const themeGen = () => {
  const res = { // eslint-disable-line
    sections: {
      se: sections,
      default: sections,
    },
    removehyphen: 'true',
  };
  return res;
};

export default themeGen;

const sections = [
  { id: 'profile', icon: 'AddressCard', title: 'phrase.mypages.profile', default: 'Min profil', url: '/my-account' },
  {
    id: 'password',
    icon: 'UserLock',
    title: 'phrase.mypages.password',
    default: 'Lösenord',
    url: '/my-account/update-password',
  },
  //{ id: 'cards', icon: 'RegCreditCard', title: 'phrase.mypages.cards', default: 'Mina kort', url: '/my-account/cards' },
  {
    id: 'deliveryAddresses',
    icon: 'Truck',
    title: 'phrase.mypages.addresses',
    default: 'Leverans- addresser',
    url: '/my-account/delivery-addresses',
  },
  {
    id: 'subscriptions',
    icon: 'CalendarAlt',
    title: 'phrase.mypages.subscriptions',
    default: 'Prenum- erationer',
    url: '/my-account/my-subscriptions',
  },
  {
    id: 'lastPurchase',
    icon: 'CartArrowDown',
    title: 'phrase.mypages.lastPurchase',
    default: 'Senaste köp',
    url: '/my-account/my-purchasedProducts',
  },
  {
    id: 'orderHistory',
    icon: 'History',
    title: 'phrase.mypages.orderHistory',
    default: 'Beställnings- historik',
    url: '/my-account/orderHistory',
  },
  {
    id: 'printers',
    icon: 'PrinterFill',
    title: 'phrase.mypages.printers',
    default: 'Beställ till min skrivare',
    typeCategory: 't1',
    url: '/my-account/printers',
  },
];
